import React, { useState, useEffect } from 'react';

const FirstGrid = (props) => {
  const { data } = props;
  const [allData, setAllData] = useState({});

  useEffect(() => {
    if(data.Contents) {
      let label = data.Contents.find(item => item.Key === "tecnologia-main-label");
      let title = data.Contents.find(item => item.Key === "tecnologia-main-title");
      let text = data.Contents.find(item => item.Key === "tecnologia-main-text");
      let gridImages = data.Contents.filter(item => item.Key.includes("tecnologia-grid-image"));

      setAllData(allData => ({
        ...allData,
        label: label ? label.Value : "ZL EZATA",
        title: title ? title.Value : "Undefined",
        text: text ? text.Value : "Undefined",
        gridImages,
      }))
    }
  }, [data])

  return (
    <section className="container-60 qualidade__e__documentacao">
      <div className="def__label__box">
        <div className="rectangle"></div>
        <p className="label">{allData.label}</p>                           
      </div>
      <h1 className="title">
        {allData.title}
      </h1>
      <p className="paragraph">
        {allData.text}
      </p>
      <div className="button__group">
        <button className="primary-button reverse">VER CERTIFICADO - INGLÊS</button>
        <button className="primary-button">BAIXAR PDF</button>
      </div>
      <div className="wrapper-5">
        {allData.gridImages ? allData.gridImages.map((item, index) => (
          <img key={index} className="img" src={item.Value} alt="tecnologia"/>  
        )) : null}        
      </div>
    </section>
  )
}

export default FirstGrid;